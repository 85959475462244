import Vue from 'vue'
import App from './App.vue'
import router from './router'
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios';
import VueLazyload from 'vue-lazyload'

Vue.use(VueLazyload, {
     loading: require('./assets/timg.gif'),
     error: require('./assets/404.png')
});
import VueAwesomeSwiper from 'vue-awesome-swiper';
import '../node_modules/swiper/swiper-bundle.css'
// import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper,)

Vue.prototype.$http  = axios
Vue.use(ElementUI);
Vue.config.productionTip = false

// 英文 en 205，中文 zh 206
let urlBase = {
  api: '//qqs.cmsback.citgroup.cn',
  locale: '206'
};
if (localStorage.getItem("language")) {
  let language = localStorage.getItem("language");
  // console.log(language);
  urlBase.locale = language;
}

axios.defaults.baseURL = urlBase.api;


//中英文切换
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
const i18n=new VueI18n({
    locale:urlBase.locale,
    messages:{
        '206':require('./components/language/zh'),
        '205':require('./components/language/en')
    }
})

new Vue({
  router,
  store,
  i18n, 
  render: h => h(App)
}).$mount('#app')
