module.exports = {
    language: {
        name: '中文'
    },
    nav: {
        homePage: 'homePage',
        travelStrategy: 'travel',
        recommendTheScenicSpot: 'recommend',
        vr: "360 VR",
        specialtyFood: 'specialty',
        popularInformation: 'popular',
        contactUs: 'contactUs'
    },
    bottom: {
        yqlj:'blogroll',
        lyjgw:'Tourism Board',
        jqwz:'Scenic sites',
        hzwz:'Cooperation',
        zwwz:'Government affairs',
        gjlyj:'National Tourism Administration',
        zjklyzxw:'Zhangjiakou Tourism information network',
        zjklyj:'Zhangjiakou Tourism Bureau',
        zjklyw:'Zhangjiakou tourism network',
        zjkysp:'Mountain slopes of Zhangjiakou',
        zjkly:'Zhangjiakou Tourism',
        xc:'Ctrip',
        fz:'Fliggy',
        lmm:'The ass mother',
        whlyb:'Ministry of Culture and Tourism',
        zjk:'Zhangjiakou',
        zjkzx:'Zhangjiakou Online',
        bottomContentOne: 'Mianyang Culture, Radio, Television and Tourism Bureau',
        bottomContentTwo: 'Beijing ICP B no.15036610-3',
        bottomContentThree: 'Zhongzhi You (Beijing) Technology Co., LTD',
        technicalSupport: 'technical support',
        citgroup: 'citgroup',
        zjkGovernment: "Mianyang Municipal Party Committee Mianyang Municipal People's Government",
        zjkCBATB: 'Mianyang Culture, Radio, Television and Tourism Bureau',
        ministryOfCultureAndTourism: 'Sichuan Provincial Department of Culture and Tourism',
        chinaTourismAcademy: 'China Tourism Academy',
        beijingCultureAndTourismBureau: 'Beijing Culture and Tourism Bureau',
        hebeiProvincialDepartmentOfCultureAndTourism: 'Hebei Provincial Department of Culture and Tourism',
    },
    content: {
        zhangjiakouChina: 'Zhangjiakou, China',
        browseMore: 'more',
        oneSBelovedMotherland: "Wenchang ancestral court",
        foodProducts: 'Food products',
        foodProductsContent: 'Zitong is known as the hometown of chefs. A group of Sichuan cuisine masters represented by Shi Zhengliang have come out. The organic integration of Zitong food culture and traditional Chinese medicine culture has launched a large number of healthy and nourishing cuisines, with eating, true taste and health in Zitong.',
        cultureAndArt: 'folk tradition and fold customs',
        cultureAndArtContent: 'There has always been a folk saying, "North Kongzi, South Wenchang".Wenchang is to Sichuan what Confucius is to Shandong!',
        characteristicLine: 'Characteristic line',
        characteristicLineContent: 'The mountains are magnificent, the peaks turn, the towering temples stand on the cliff, the trees hang shade, the scenery is beautiful, known as "the pearl of Shu Road".',
        picturesque: 'picturesque',
        travelGuide: 'Travel guide',
        inTheBeauty: 'In the beauty',
        qiaoxiqu: "qiaoxi",
        chongliqu: "Chung li area",
        huailaixian: "Huailai county",
        chichengxian: "Chicheng county",
        zhangbeixian: "Zhangbei county",
        theRecommendedStrategy: 'The recommended strategy',
        theRecommendedStrategyContent: 'After 365 days of practical experience for you to select the most beautiful route planning recommendations',
        hotLine: 'Hot line',
        inTheBeauty: 'In the beauty',
        knowMoreAbout: 'more',
        popularDestination: 'popular destination',
        viewDetails: 'view details',
        recommendedCircuit: 'Recommended circuit',
        viewMore: 'more',
        savorFood: 'savor food',
        aboutUs: 'About Us',
        aboutUsContent: "Zitong is known as the hometown of chefs. A group of Sichuan cuisine masters represented by Shi Zhengliang have come out. The organic integration of Zitong food culture and traditional Chinese medicine culture has launched a large number of healthy and nourishing cuisines, with eating, true taste and health in Zitong.",
        specialty: 'specialty',
        newsRankings: 'News rankings',
        releaseTime: 'release time',
        source: 'source',
        recommendedAttractions: 'Recommended attractions',
        recommendedDailyNews: 'Recommended Daily News',
        theGamesAreZone: 'wonderful activity',
        cityCard: 'city card',
    }
}