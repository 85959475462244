module.exports = {
    language: {
        name: 'English'
    },
    nav: {
        homePage: '首页',
        travelStrategy: '旅游攻略',
        recommendTheScenicSpot: '推荐景区',
        vr: "全景VR",
        specialtyFood: '特产美食',
        popularInformation: '热门资讯',
        contactUs: '联系我们'
    },
    bottom: {
        yqlj:'友情链接',
        lyjgw:'旅游局网站',
        jqwz:'景区网站',
        hzwz:'合作网站',
        zwwz:'政务网站',
        gjlyj:'国家旅游局',
        zjklyzxw:'张家口旅游资讯网',
        zjklyj:'张家口旅游局',
        zjklyw:'张家口旅游网',
        zjkysp:'张家口野山坡',
        zjkly:'张家口旅游',
        xc:'携程',
        fz:'飞猪',
        lmm:'驴妈妈',
        whlyb:'文化旅游部',
        zjk:'张家口',
        zjkzx:'张家口在线',
        bottomContentOne: '绵阳七曲山旅游开发有限公司',
        bottomContentTwo: '蜀ICP备20014829号-2',
        bottomContentThree: '中智游（北京）科技有限公司',
        technicalSupport: '技术支持',
        citgroup: '中智游',
        zjkGovernment: '绵阳市人民政府',
        zjkCBATB: '绵阳七曲山旅游开发有限公司',
        ministryOfCultureAndTourism: '文化和旅游部',
        chinaTourismAcademy: '中国旅游研究院',
        beijingCultureAndTourismBureau: '北京市文化和旅游局',
        hebeiProvincialDepartmentOfCultureAndTourism: '河北省文化和旅游厅',
        
    },
    content: {
        zhangjiakouChina: '中国张家口',
        browseMore: '浏览更多',
        oneSBelovedMotherland: '文昌祖庭',
        foodProducts: '美食特产',
        foodProductsContent: '梓潼是著名的厨师之乡。 以史正良为代表的一批川菜名家已经出来了。 梓潼饮食文化与中医传统文化的有机融合，推出了一大批以吃、真味、健康为特色的紫通养生美食。',
        cultureAndArt: '文化艺术',
        cultureAndArtContent: '民间有云:“北有孔子，南有文昌”。 文昌之于四川，就像孔子之于山东!',
        characteristicLine: '特色线路',
        characteristicLineContent: '山势雄伟，峰峦起伏，高耸的庙宇耸立在悬崖上，树木垂荫，景色秀丽，被誉为“蜀道明珠”。',
        picturesque: '风景如画',
        travelGuide: '游记攻略',
        inTheBeauty: '地区美景',
        qiaoxiqu: "桥西区",
        chongliqu: "崇礼区",
        huailaixian: "怀来县",
        chichengxian: "赤城县",
        zhangbeixian: "张北县",
        theRecommendedStrategy: '推荐攻略',
        theRecommendedStrategyContent: '推经过365天的实际体验为您精挑细选出最优美尽兴的路线规划荐攻略',
        hotLine: '热门线路',
        inTheBeauty: '当季美景',
        knowMoreAbout: '了解更多',
        popularDestination: '热门景点',
        viewDetails: '查看详情',
        recommendedCircuit: '推荐线路',
        viewMore: '查看更多',
        savorFood: '品尝美食',
        aboutUs: '关于我们',
        aboutUsContent: '梓潼是著名的厨师之乡。 以史正良为代表的一批川菜名家已经出来了。 梓潼饮食文化与中医传统文化的有机融合，推出了一大批以吃、真味、健康为特色的梓潼养生美食。',
        specialty: '特产',
        newsRankings: '新闻排行',
        releaseTime: '发布时间',
        source: '来源',
        recommendedAttractions: '推荐景点',
        recommendedDailyNews: '每日推荐新闻',
        theGamesAreZone: '精彩活动',
        cityCard: '城市名片',
    }
}